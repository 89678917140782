export interface Message {
  id: string;
  content: string;
  role: "assistant" | "user";
  createdAt?: number | Date;
  promptSuggestions?: Array<{
    label: string;
    value: string;
  }>;
}

export interface AIChatProps {
  thread: Message[];
  threadId?: string;
  prompt: string;
  promptInputPlaceholder?: string;
  chatTitle?: string;
  chatDescription?: string;
  isWaitingForResponse?: boolean;
  onPromptChange: (prompt: string) => void;
  onSubmit?: () => void;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onAttachFiles?: (files: FileList) => void;
  onDeleteThread?: () => void;
  isThreadLoading: boolean;
  size?: keyof typeof CHAT_SIZE;
}

export const CHAT_SIZE = {
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  FIT_PAGE: "FIT_PAGE",
} as const;
