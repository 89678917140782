import React from "react";
import { Flex, Markdown, Text } from "@appsmith/wds";
import { AIAvatarCustomIcon } from "appsmith-icons";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import type { Message } from "./types";
import styles from "./styles.module.css";
import { ChatPromptSuggestions } from "./ChatPromptSuggestions";

interface ChatMessageItemProps {
  message: Message;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  isAssistantSuggestionVisible?: boolean;
}

export const ChatMessageItem = (props: ChatMessageItemProps) => {
  const { isAssistantSuggestionVisible, message, onApplyAssistantSuggestion } =
    props;
  const { content, createdAt, promptSuggestions = [], role } = message;

  const getFormatedDate = (createdAt: number | Date) => {
    if (createdAt instanceof Date) return format(createdAt, "HH:mm, dd MMM");

    // Convert to milliseconds for JavaScript Date
    const date = new Date(createdAt * 1000);

    // Get the user's local time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the user's time zone
    const zonedDate = toZonedTime(date, timeZone);

    // Format the date in "HH:mm, dd MMM" format
    return format(zonedDate, "HH:mm, dd MMM");
  };

  const assistantMessage = (
    <Flex direction="row" gap="spacing-3" isInner minWidth="spacing-0">
      <AIAvatarCustomIcon
        area-label="Appsmith AI"
        className={styles.avatarImage}
      />
      <Flex direction="column" gap="spacing-4" minWidth="spacing-0">
        {createdAt && <Text size="caption">{getFormatedDate(createdAt)}</Text>}
        <Markdown data-markdown="">{content}</Markdown>
        {isAssistantSuggestionVisible && (
          <ChatPromptSuggestions
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            suggestions={promptSuggestions}
          />
        )}
      </Flex>
    </Flex>
  );

  const userMessage = (
    <Flex
      alignItems="end"
      className={styles.userMessage}
      direction="column"
      gap="spacing-3"
      isInner
    >
      {createdAt && <Text size="caption">{getFormatedDate(createdAt)}</Text>}
      <Text wordBreak="break-word">{content}</Text>
    </Flex>
  );

  return (
    <Flex
      className={styles.messageListItem}
      direction={role === "assistant" ? "row" : "row-reverse"}
      key={message.id}
    >
      {role === "assistant" && assistantMessage}
      {role === "user" && userMessage}
    </Flex>
  );
};
