import React, { useState } from "react";
import { Button, Flex, Sidebar, SidebarProvider } from "@appsmith/wds";

import styles from "./styles.module.css";
import { ChatHeader } from "./ChatHeader";
import { type AIChatProps } from "./types";
import { ChatMessageList } from "./ChatMessageList";
import { ChatInputSection } from "./ChatInputSection";
import { useAIChatHeight } from "../hooks/useAIChatHeight";
import { ChatCitationContent } from "./ChatCitationContent";

export const AIChat = (props: AIChatProps) => {
  const {
    chatDescription,
    chatTitle,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    size,
    thread,
    threadId,
  } = props;
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const chatHeight = useAIChatHeight(size, isEmbed);
  const isDeleteThreadDisabled =
    !threadId || isThreadLoading || isWaitingForResponse;
  const [tempCitation, setTempCitation] = useState<string | null>(null);
  const [selectedCitation, setSelectedCitation] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleCitationSelect = (citationText: string) => {
    setTempCitation(citationText);

    if (isSidebarOpen) {
      setSelectedCitation(citationText);

      return;
    }

    setIsSidebarOpen(true);
  };

  return (
    <SidebarProvider
      className={styles.aiChat}
      defaultState="collapsed"
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      <Flex alignItems="stretch" direction="column" width="100%">
        <ChatHeader
          description={chatDescription}
          isDeleteThreadDisabled={isDeleteThreadDisabled}
          onDeleteThread={onDeleteThread}
          title={chatTitle}
        />
        <ChatMessageList
          isThreadLoading={isThreadLoading}
          onApplyAssistantSuggestion={onApplyAssistantSuggestion}
          thread={thread}
        />
        <Button onPress={() => handleCitationSelect(crypto.randomUUID())}>
          Test citation
        </Button>
        <ChatInputSection
          isThreadLoading={isThreadLoading}
          isWaitingForResponse={isWaitingForResponse}
          onAttachFiles={onAttachFiles}
          onPromptChange={onPromptChange}
          onSubmit={onSubmit}
          prompt={prompt}
          promptInputPlaceholder={promptInputPlaceholder}
        />
      </Flex>
      <Sidebar
        onEntered={() => {
          if (tempCitation) {
            setSelectedCitation(tempCitation);
            setTempCitation(null);
          }
        }}
        side="end"
        title="SOP"
      >
        <ChatCitationContent citation={selectedCitation} />
      </Sidebar>
    </SidebarProvider>
  );
};
